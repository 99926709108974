const Author = {
  LVX: {
    author: "Lux Rota",
    authorLink: "https://www.luxrota.com/",
  },
}
const License = {
  CC0: {
    license: "CC0",
    licenseLink: "https://creativecommons.org/share-your-work/public-domain/cc0",
  },
  CCBY: {
    license: "CC BY",
    licenseLink: "https://creativecommons.org/licenses/by/3.0/deed.en",
  },
  CCBYND: {
    license: "CC BY-ND",
    licenseLink: "https://creativecommons.org/licenses/by-nd/2.0/",
  },
  CCBYSA: {
    license: "CC BY-SA",
    licenseLink: "https://creativecommons.org/licenses/by-sa/3.0/deed.en",
  },
  Pixabay: {
    license: "Pixabay",
    licenseLink: "https://pixabay.com/service/license/",
  },
}


// Export Credits
module.exports = {
  // Accords
  "accords/animal.jpg": {
    author: "14398",
    authorLink: "https://pixabay.com/users/14398-14398/",
    link: "https://pixabay.com/photos/mink-fur-taxidermy-hair-fluffy-247488",
    title: "Mink Fur",
    ...License.Pixabay,
  },
  "accords/citrus.jpg": {
    author: "Obodai26",
    authorLink: "https://pixabay.com/users/Obodai26-14094971/",
    link: "https://pixabay.com/photos/orange-citrus-fruit-natural-fruits-4663073/",
    title: "Citrus Fruit",
    ...License.Pixabay,
  },
  "accords/color.jpg": {
    author: "Couleur",
    authorLink: "https://pixabay.com/users/Couleur-1195798/",
    link: "https://pixabay.com/photos/tulip-blossom-bloom-violet-flowers-3365630/",
    title: "Tulip Blossom",
    ...License.Pixabay,
  },
  "accords/dry.jpg": {
    author: "kloxklox",
    authorLink: "https://pixabay.com/users/kloxklox_com-242049/",
    link: "https://pixabay.com/photos/wood-log-annual-rings-grain-tribe-366735/",
    title: "Wood Log Grain",
    ...License.Pixabay,
  },
  "accords/fruit.jpg": {
    author: "Couleur",
    authorLink: "https://pixabay.com/users/Couleur-1195798/",
    link: "https://pixabay.com/photos/apricots-sugar-apricots-fruit-2523272/",
    title: "Apricot Fruits",
    ...License.Pixabay,
  },
  "accords/green.jpg": {
    author: "stevepb",
    authorLink: "https://pixabay.com/users/stevepb-282134/",
    link: "https://pixabay.com/photos/leaf-green-foliage-green-leaves-1001679/",
    title: "Green Leaves",
    ...License.Pixabay,
  },
  "accords/herb.jpg": {
    author: "gate74",
    authorLink: "https://pixabay.com/users/gate74-5942741/",
    link: "https://pixabay.com/photos/herbs-french-bouquet-gourmet-2523119/",
    title: "Herbs Bouquet",
    ...License.Pixabay,
  },
  "accords/moss.jpg": {
    author: "grahamwallis",
    authorLink: "https://pixabay.com/users/grahamwallis-788195/",
    link: "https://pixabay.com/photos/moss-branch-green-nature-forest-644204/",
    title: "Green Moss",
    ...License.Pixabay,
  },
  "accords/resin.jpg": {
    author: "montemari",
    authorLink: "https://pixabay.com/users/montemari-435825/",
    link: "https://pixabay.com/photos/gum-arabic-acacia-resin-binder-763043/",
    title: "Acacia Resin",
    ...License.Pixabay,
  },
  "accords/soft.jpg": {
    author: "Anelka",
    authorLink: "https://pixabay.com/users/Anelka-217857/",
    link: "https://pixabay.com/photos/pansy-flowers-plant-nature-spring-327188/",
    title: "Violet Blossoms",
    ...License.Pixabay,
  },
  "accords/spice.jpg": {
    author: "babawawa",
    authorLink: "https://pixabay.com/users/babawawa-299001/",
    link: "https://pixabay.com/photos/spice-chiles-paprika-chili-powder-370114/",
    title: "Various Spices",
    ...License.Pixabay,
  },
  "accords/sweet.jpg": {
    author: "jarmoluk",
    authorLink: "https://pixabay.com/users/jarmoluk-143740/",
    link: "https://pixabay.com/photos/bananas-dessert-sweets-282313/",
    title: "Dessert Sweets",
    ...License.Pixabay,
  },
  "accords/timber.jpg": {
    author: "PublicDomainPictures",
    authorLink: "https://pixabay.com/users/PublicDomainPictures-14/",
    link: "https://pixabay.com/photos/lumber-wood-tree-log-tree-brown-84678/",
    title: "Wood Grain",
    ...License.Pixabay,
  },
  "accords/water.jpg": {
    author: "CharlesRondeau",
    authorLink: "https://pixabay.com/users/CharlesRondeau-1458430/",
    link: "https://pixabay.com/photos/water-swimming-pool-bathing-travel-1018808/",
    title: "Water",
    ...License.Pixabay,
  },
  "accords/white.jpg": {
    author: "ViJakob",
    authorLink: "https://pixabay.com/users/ViJakob-13591601/",
    link: "https://pixabay.com/photos/wood-anemone-the-beginning-of-spring-4770523/",
    title: "Spring Begins",
    ...License.Pixabay,
  },

  // Brands
  "brands.jpg": {
    author: "PxHere",
    authorLink: "https://pxhere.com/",
    link: "https://pxhere.com/sv/photo/1200561",
    title: "Perfume Bottles",
    ...License.CC0,
  },

  // Countries
  "countries.jpg": {
    author: "Free-Photos",
    authorLink: "https://pixabay.com/users/Free-Photos-242387/",
    link: "https://pixabay.com/photos/globes-spheres-maps-ball-world-1246245/",
    title: "Globes Spheres Maps",
    ...License.Pixabay,
  },
  "countries/es.jpg": {
    author: "papagnoc",
    authorLink: "https://pixabay.com/users/papagnoc-1605484/",
    link: "https://pixabay.com/photos/valencia-spain-calatrava-sunset-1049389/",
    title: "Valencia Spain",
    ...License.Pixabay,
  },
  "countries/fr.jpg": {
    author: "TheDigitalArtist",
    authorLink: "https://pixabay.com/users/TheDigitalArtist-202249/",
    link: "https://pixabay.com/photos/eiffel-tower-paris-france-travel-3349075/",
    title: "Paris France",
    ...License.Pixabay,
  },
  "countries/gb.jpg": {
    author: "Julius_Silver",
    authorLink: "https://pixabay.com/users/Julius_Silver-4371822/",
    link: "https://pixabay.com/photos/london-tower-bridge-england-3078109/",
    title: "London Tower Bridge",
    ...License.Pixabay,
  },
  "countries/it.jpg": {
    author: "Free-Photos",
    authorLink: "https://pixabay.com/users/Free-Photos-242387/",
    link: "https://pixabay.com/photos/canal-venice-italy-water-river-1209808/",
    title: "Venice Italy Canals",
    ...License.Pixabay,
  },
  "countries/jp.jpg": {
    author: "sofi5t",
    authorLink: "https://pixabay.com/users/sofi5t-6957643/",
    link: "https://pixabay.com/photos/japan-travel-nature-asia-plant-4141578/",
    title: "Tokyo Japan",
    ...License.Pixabay,
  },
  "countries/us.jpg": {
    author: "Free-Photos",
    authorLink: "https://pixabay.com/users/Free-Photos-242387/",
    link: "https://pixabay.com/photos/golden-gate-bridge-san-francisco-388917/",
    title: "San Francisco",
    ...License.Pixabay,
  },

  // Families
  "families/fresh.jpg": {
    author: "ronymichaud",
    authorLink: "https://pixabay.com/users/ronymichaud-647623/",
    link: "https://pixabay.com/photos/drops-of-water-water-liquid-fresh-578897/",
    title: "Drop of Water",
    ...License.Pixabay,
  },
  "families/floral.jpg": {
    author: "Couleur",
    authorLink: "https://pixabay.com/users/Couleur-1195798/",
    link: "https://pixabay.com/photos/roses-bloom-rosenstock-rose-bloom-3611502/",
    title: "Roses Bloom",
    ...License.Pixabay,
  },
  "families/oriental.jpg": {
    author: "Tama66",
    authorLink: "https://pixabay.com/users/Tama66-1032521/",
    link: "https://pixabay.com/photos/market-stand-spices-food-3466906/",
    title: "Market Spices",
    ...License.Pixabay,
  },
  "families/woods.jpg": {
    author: "jplenio",
    authorLink: "https://pixabay.com/users/jplenio-7645255/",
    link: "https://pixabay.com/photos/tree-nature-wood-sunset-light-3822149/",
    title: "Tree Sunset",
    ...License.Pixabay,
  },

  // Home
  "index.jpg": {
    author: "monicore",
    authorLink: "https://pixabay.com/users/monicore-1499084/",
    link: "https://pixabay.com/photos/bottle-perfume-perfume-bottle-glass-2387210/",
    title: "Bottle Perfume",
    ...License.Pixabay,
  },

  // Notes
  "notes.jpg": {
    author: "Various Artists",
    authorLink: "https://pixabay.com/users/",
    link: "https://pixabay.com/photos/",
    title: "Note Families",
    ...License.Pixabay,
  },
  "notes/amber.jpg": {
    author: "jsjgeology",
    authorLink: "https://www.flickr.com/photos/jsjgeology/",
    link: "https://www.flickr.com/photos/jsjgeology/15545782135",
    title: "Amber Resinite",
    ...License.CCBY,
  },
  "notes/ambrette.jpg": {
    author: "lennyworthington",
    authorLink: "https://www.flickr.com/photos/lennyworthington/",
    link: "https://www.flickr.com/photos/lennyworthington/7981713600/",
    title: "Musk Mallow",
    ...License.CCBYSA,
  },
  "notes/bamboo.jpg": {
    author: "clarabsp",
    authorLink: "https://pixabay.com/users/clarabsp-125496/",
    link: "https://pixabay.com/photos/bamboo-green-plant-240321/",
    title: "Bamboo Plant",
    ...License.Pixabay,
  },
  "notes/benzoin.jpg": {
    author: "Wie146",
    authorLink: "https://commons.wikimedia.org/wiki/User:Wie146",
    link: "https://commons.wikimedia.org/wiki/File:Kemenyan_110112-11054_tdp.jpg",
    title: "Benzoin Resin",
    ...License.CCBYSA,
  },
  "notes/bergamot.jpg": {
    author: "41330",
    authorLink: "https://pixabay.com/users/41330-41330/",
    link: "https://pixabay.com/photos/bergamot-fruit-leaf-isolated-1554278/",
    title: "Bergamot Fruit",
    ...License.Pixabay,
  },
  "notes/blackberry.jpg": {
    author: "pixel2013",
    authorLink: "https://pixabay.com/users/pixel2013-2364555/",
    link: "https://pixabay.com/photos/blackberries-bramble-berries-bush-1539540/",
    title: "Blackberry Bramble",
    ...License.Pixabay,
  },
  "notes/blackcurrant.jpg": {
    author: "PublicDomainPictures",
    authorLink: "https://pixabay.com/users/PublicDomainPictures-14/",
    link: "https://pixabay.com/photos/berry-black-currant-blackcurrant-71565/",
    title: "Blackcurrant",
    ...License.Pixabay,
  },
  "notes/brown-sugar.jpg": {
    author: "GabiSanda",
    authorLink: "https://pixabay.com/users/GabiSanda-2464323/",
    link: "https://pixabay.com/photos/sugar-brown-sugar-sweet-brown-food-1354787/",
    title: "Brown Sugar",
    ...License.Pixabay,
  },
  "notes/caramel.jpg": {
    author: "dghchocolatier",
    authorLink: "https://pixabay.com/users/dghchocolatier-5671698/",
    link: "https://pixabay.com/photos/yorkshire-fudge-cream-toffee-2945345/",
    title: "Caramel Toffee",
    ...License.Pixabay,
  },
  "notes/carnation.jpg": {
    author: "Pezibear",
    authorLink: "https://pixabay.com/users/Pezibear-526143/",
    link: "https://pixabay.com/photos/flowers-pink-cloves-cut-flowers-1325012/",
    title: "Pink Carnations",
    ...License.Pixabay,
  },
  "notes/carrot.jpg": {
    author: "klimkin",
    authorLink: "https://pixabay.com/users/klimkin-1298145/",
    link: "https://pixabay.com/photos/carrot-growth-vegetables-1565597/",
    title: "Carrot Growth",
    ...License.Pixabay,
  },
  "notes/cashmeran.jpg": {
    author: "milele",
    authorLink: "https://www.flickr.com/photos/milele/",
    link: "https://www.flickr.com/photos/milele/7189976288",
    title: "Opal Cashmere",
    ...License.CCBY,
  },
  "notes/cassia.jpg": {
    author: "dezalb",
    authorLink: "https://pixabay.com/users/DEZALB-1045091/",
    link: "https://pixabay.com/photos/flower-yellow-petals-cassia-5477079/",
    title: "Cassia Petals",
    ...License.Pixabay,
  },
  "notes/cedar.jpg": {
    author: "djedj",
    authorLink: "https://pixabay.com/users/djedj-59194/",
    link: "https://pixabay.com/photos/trunk-wood-cedar-detail-texture-3663773/",
    title: "Cedar Wood",
    ...License.Pixabay,
  },
  "notes/champaca.jpg": {
    author: "Josch13",
    authorLink: "https://pixabay.com/users/Josch13-48777/",
    link: "https://pixabay.com/photos/michelia-champaca-blossom-blossom-340540/",
    title: "Champaca Blossom",
    ...License.Pixabay,
  },
  "notes/chamomile.jpg": {
    author: "cocoparisienne",
    authorLink: "https://pixabay.com/users/cocoparisienne-127419/",
    link: "https://pixabay.com/photos/chamomile-chamomile-flower-401490/",
    title: "Chamomile",
    ...License.Pixabay,
  },
  "notes/cherry-blossom.jpg": {
    author: "Hans",
    authorLink: "https://pixabay.com/users/Hans-2/",
    link: "https://pixabay.com/photos/cherry-blossom-japanese-cherry-smell-1260641/",
    title: "Cherry Blossom",
    ...License.Pixabay,
  },
  "notes/citrus.jpg": {
    author: "Myriams-Fotos",
    authorLink: "https://pixabay.com/users/Myriams-Fotos-1627417/",
    link: "https://pixabay.com/photos/slice-of-lemon-lemon-small-bubbles-2135548/",
    title: "Lemon Bubbles",
    ...License.Pixabay,
  },
  "notes/clary-sage.jpg": {
    author: "Didgeman",
    authorLink: "https://pixabay.com/users/Didgeman-153208/",
    link: "https://pixabay.com/photos/blossom-bloom-clary-sage-4356025/",
    title: "Clary Sage",
    ...License.Pixabay,
  },
  "notes/coffee.jpg": {
    author: "Alexas_Fotos",
    authorLink: "https://pixabay.com/users/Alexas_Fotos-686414/",
    link: "https://pixabay.com/photos/coffee-coffee-beans-cafe-roasted-1291656/",
    title: "Coffee Beans",
    ...License.Pixabay,
  },
  "notes/cream.jpg": {
    author: "danymena88",
    authorLink: "https://pixabay.com/users/danymena88-1325815/",
    link: "https://pixabay.com/photos/dessert-drink-ice-cream-beverage-2199271/",
    title: "Creamy Shake",
    ...License.Pixabay,
  },
  "notes/cosmos-flower.jpg": {
    author: "Capri23auto",
    authorLink: "https://pixabay.com/users/Capri23auto-1767157/",
    link: "https://pixabay.com/photos/cosmea-flower-cosmos-blossom-bloom-3711568/",
    title: "Cosmos Blossom",
    ...License.Pixabay,
  },
  "notes/cucumber.jpg": {
    author: "krzys16",
    authorLink: "https://pixabay.com/users/krzys16-1195925/",
    link: "https://pixabay.com/photos/cucumbers-vegetables-green-healthy-849269/",
    title: "Fresh Cucumber",
    ...License.Pixabay,
  },
  "notes/elemi.jpg": {
    author: "Yintan",
    authorLink: "https://en.wikipedia.org/wiki/User:Yintan",
    link: "https://en.wikipedia.org/wiki/File:Resin_on_Almond_tree.jpg",
    title: "Tree Resin",
    ...License.Pixabay,
  },
  "notes/freesia.jpg": {
    author: "Buntysmum",
    authorLink: "https://pixabay.com/users/Buntysmum-5497946/",
    link: "https://pixabay.com/photos/flower-freesia-fragrant-perfume-4526658/",
    title: "Freesia Flower",
    ...License.Pixabay,
  },
  "notes/galbanum.jpg": {
    author: "Andrew Massyn",
    authorLink: "https://en.wikipedia.org/wiki/File:Peucedanum_galbanum_Blister_bush_Table_mt_(1).JPG",
    link: "https://en.wikipedia.org/wiki/File:Peucedanum_galbanum_Blister_bush_Table_mt_(1).JPG",
    title: "Galbanum Bush",
    ...License.Pixabay,
  },
  "notes/gardenia.jpg": {
    author: "oom_endro",
    authorLink: "https://pixabay.com/users/oom_endro-51431/",
    link: "https://pixabay.com/photos/gardenia-floral-plant-natural-178719/",
    title: "Gardenia Floral",
    ...License.Pixabay,
  },
  "notes/geranium.jpg": {
    author: "MrGajowy3",
    authorLink: "https://pixabay.com/users/MrGajowy3-7396567/",
    link: "https://pixabay.com/photos/geranium-flowers-potted-pink-macro-5219631/",
    title: "Geranium Flowers",
    ...License.Pixabay,
  },
  "notes/goldenberry.jpg": {
    author: "Toyah",
    authorLink: "https://commons.wikimedia.org/wiki/File:Golden_berry.JPG",
    link: "https://commons.wikimedia.org/wiki/File:Golden_berry.JPG",
    title: "Goldenberry",
    ...License.CC0,
  },
  "notes/grapefruit.jpg": {
    author: "ExplorerBob",
    authorLink: "https://pixabay.com/users/ExplorerBob-3093227/",
    link: "https://pixabay.com/photos/food-fruit-grapefruit-fruits-2563404/",
    title: "Grapefruits",
    ...License.Pixabay,
  },
  "notes/hazelnut.jpg": {
    author: "9883074",
    authorLink: "https://pixabay.com/users/9883074-9883074/",
    link: "https://pixabay.com/photos/hazelnuts-nuts-food-brown-protein-3752184/",
    title: "Brown Hazelnuts",
    ...License.Pixabay,
  },
  "notes/honey.jpg": {
    author: "stevepb",
    authorLink: "https://pixabay.com/users/stevepb-282134/",
    link: "https://pixabay.com/photos/honey-sweet-syrup-organic-golden-1006972/",
    title: "Golden Honey",
    ...License.Pixabay,
  },
  "notes/honeysuckle.jpg": {
    author: "adamtepl",
    authorLink: "https://pixabay.com/users/adamtepl-3296308/",
    link: "https://pixabay.com/photos/honeysuckle-flower-spring-creeper-3433072/",
    title: "Honeysuckle Flower",
    ...License.Pixabay,
  },
  "notes/iris.jpg": {
    author: "pixel2013",
    authorLink: "https://pixabay.com/users/pixel2013-2364555/",
    link: "https://pixabay.com/photos/iris-flower-garden-purple-flora-1420050/",
    title: "Iris Flower",
    ...License.Pixabay,
  },
  "notes/jasmine.jpg": {
    author: "pisauikan",
    authorLink: "https://pixabay.com/users/pisauikan-4552082/",
    link: "https://pixabay.com/photos/orange-jasmine-murraya-exotica-4959287/",
    title: "Orange Jasmine",
    ...License.Pixabay,
  },
  "notes/lemon.jpg": {
    author: "rauschenberger",
    authorLink: "https://pixabay.com/users/rauschenberger-4614580/",
    link: "https://pixabay.com/photos/fruit-leaf-food-juicy-citrus-3287620/",
    title: "Juicy Lemons",
    ...License.Pixabay,
  },
  "notes/lily.jpg": {
    author: "Capri23auto",
    authorLink: "https://pixabay.com/users/Capri23auto-1767157/",
    link: "https://pixabay.com/photos/lily-blossom-bloom-lilies-stamen-3763573/",
    title: "Lily Blossom",
    ...License.Pixabay,
  },
  "notes/limoncello.jpg": {
    author: "LNLNLN",
    authorLink: "https://pixabay.com/users/LNLNLN-1131094/",
    link: "https://pixabay.com/photos/limoncello-liqueur-brandy-italy-4663000/",
    title: "Limoncello Liqueur",
    ...License.Pixabay,
  },
  "notes/magnolia.jpg": {
    author: "MabelAmber",
    authorLink: "https://pixabay.com/users/MabelAmber-1377835/",
    link: "https://pixabay.com/photos/magnolia-flower-petal-tree-4129158/",
    title: "Magnolia Flower",
    ...License.Pixabay,
  },
  "notes/mandarin.jpg": {
    author: "Daria-Yakovleva",
    authorLink: "https://pixabay.com/users/Daria-Yakovleva-3938704/",
    link: "https://pixabay.com/photos/mandarins-fruit-citrus-sunlight-2043983/",
    title: "Tasty Mandarins",
    ...License.Pixabay,
  },
  "notes/marigold.jpg": {
    author: "donvikro",
    authorLink: "https://pixabay.com/users/donvikro-46639/",
    link: "https://pixabay.com/photos/butterfly-yellow-insect-nature-196745/",
    title: "Marigold Butterfly",
    ...License.Pixabay,
  },
  "notes/muguet.jpg": {
    author: "Peggychoucair",
    authorLink: "https://pixabay.com/users/Peggychoucair-1130890/",
    link: "https://pixabay.com/photos/lily-of-the-valley-convallaria-4175677/",
    title: "Muguet Flowers",
    ...License.Pixabay,
  },
  "notes/musk.jpg": {
    author: "photodrishti",
    authorLink: "https://pixabay.com/users/photodrishti-11681339/",
    link: "https://pixabay.com/photos/deer-nepal-chitwan-nationa-park-4944404/",
    title: "Deer of Nepal",
    ...License.Pixabay,
  },
  "notes/narcissus.jpg": {
    author: "Capri23auto",
    authorLink: "https://pixabay.com/users/Capri23auto-1767157/",
    link: "https://pixabay.com/photos/narcissus-blossom-bloom-white-4162300/",
    title: "Narcissus Flowers",
    ...License.Pixabay,
  },
  "notes/neroli.jpg": {
    author: "easygiving",
    authorLink: "https://www.flickr.com/photos/easygiving/",
    link: "https://www.flickr.com/photos/36733916@N07/4025469058",
    title: "Neroli Blossom",
    ...License.CCBYND,
  },
  "notes/orange.jpg": {
    author: "pixel2013",
    authorLink: "https://pixabay.com/users/pixel2013-2364555/",
    link: "https://pixabay.com/photos/orange-citrus-fruit-fruit-healthy-1995056/",
    title: "Orange Fruit",
    ...License.Pixabay,
  },
  "notes/orange-blossom.jpg": {
    author: "Hans",
    authorLink: "https://pixabay.com/users/Hans-2/",
    link: "https://pixabay.com/photos/orange-blossom-orange-tree-blossom-1117411/",
    title: "Orange Blossom",
    ...License.Pixabay,
  },
  "notes/orchid.jpg": {
    author: "JACLOU-DL",
    authorLink: "https://pixabay.com/users/JACLOU-DL-5602247/",
    link: "https://pixabay.com/photos/flower-orchid-pink-flower-4243681/",
    title: "Pink Orchid",
    ...License.Pixabay,
  },
  "notes/osmanthus.jpg": {
    author: "ndrwfgg",
    authorLink: "https://www.flickr.com/photos/ndrwfgg/",
    link: "https://www.flickr.com/photos/69024001@N00/5605759524",
    title: "Osmanthus",
    ...License.CCBY,
  },
  "notes/patchouli.jpg": {
    author: "starr",
    authorLink: "http://www.hear.org/starr/",
    link: "https://commons.wikimedia.org/wiki/File:Starr_070906-8832_Pogostemon_cablin.jpg",
    title: "Patchouli Leaves",
    ...License.CCBY,
  },
  "notes/peach.jpg": {
    author: "Couleur",
    authorLink: "https://pixabay.com/users/Couleur-1195798/",
    link: "https://pixabay.com/photos/peach-fruit-red-yellow-juicy-ripe-2573836/",
    title: "Juicy Peaches",
    ...License.Pixabay,
  },
  "notes/pear.jpg": {
    author: "PIRO4D",
    authorLink: "https://pixabay.com/users/PIRO4D-2707530/",
    link: "https://pixabay.com/photos/fruit-pear-pear-basket-sweet-bio-1534494/",
    title: "Pear Basket",
    ...License.Pixabay,
  },
  "notes/peony.jpg": {
    author: "Capri23auto",
    authorLink: "https://pixabay.com/users/Capri23auto-1767157/",
    link: "https://pixabay.com/photos/peony-rose-bud-pentecost-blossom-4892653/",
    title: "Peony Blossom",
    ...License.Pixabay,
  },
  "notes/persimmon.jpg": {
    author: "jackmac34",
    authorLink: "https://pixabay.com/users/jackmac34-483877/",
    link: "https://pixabay.com/photos/fruit-persimmons-fall-orange-1100023/",
    title: "Persimmon Fruit",
    ...License.Pixabay,
  },
  "notes/pink-pepper.jpg": {
    author: "Saramukitza",
    authorLink: "https://pixabay.com/users/Saramukitza-2447148/",
    link: "https://pixabay.com/photos/pink-pepper-spice-kitchen-red-1670771/",
    title: "Pink Pepper",
    ...License.Pixabay,
  },
  "notes/plum.jpg": {
    author: "Daria-Yakovleva",
    authorLink: "https://pixabay.com/users/Daria-Yakovleva-3938704/",
    link: "https://pixabay.com/photos/plum-box-fruit-vitamins-garden-1898196/",
    title: "Plum Box",
    ...License.Pixabay,
  },
  "notes/pomegranate.jpg": {
    author: "falco",
    authorLink: "https://pixabay.com/users/falco-81448/",
    link: "https://pixabay.com/photos/bazaar-istanbul-market-turkey-775939/",
    title: "Pomegranate Fruit",
    ...License.Pixabay,
  },
  "notes/powder.jpg": {
    author: "Piqsels",
    authorLink: "https://www.piqsels.com/en/public-domain-photo-sgxvp",
    link: "https://www.piqsels.com/en/public-domain-photo-sgxvp",
    title: "Makeup Powder",
    ...License.CC0,
  },
  "notes/praline.jpg": {
    author: "pixel2013",
    authorLink: "https://pixabay.com/users/pixel2013-2364555/",
    link: "https://pixabay.com/photos/chocolates-chocolate-confiserie-1737462/",
    title: "Candy Nibbles",
    ...License.Pixabay,
  },
  "notes/primrose.jpg": {
    author: "matthiasboeckel",
    authorLink: "https://pixabay.com/users/matthiasboeckel-3930681/",
    link: "https://pixabay.com/photos/primroses-blossom-bloom-spring-4999716/",
    title: "Primroses",
    ...License.Pixabay,
  },
  "notes/quince.jpg": {
    author: "music4life",
    authorLink: "https://pixabay.com/users/music4life-19559/",
    link: "https://pixabay.com/photos/ornamental-quince-fruit-bush-fruits-2684072/",
    title: "Quince Fruit",
    ...License.Pixabay,
  },
  "notes/rangoon-creeper.jpg": {
    author: "kumaravel",
    authorLink: "https://www.flickr.com/photos/kumaravel/",
    link: "https://www.flickr.com/photos/kumaravel/8713798193",
    title: "Rangoon Creeper",
    ...License.CCBY,
  },
  "notes/raspberry.jpg": {
    author: "ulleo",
    authorLink: "https://pixabay.com/users/ulleo-1834854/",
    link: "https://pixabay.com/photos/raspberry-fruit-berry-red-ripe-3454504/",
    title: "Red Raspberry",
    ...License.Pixabay,
  },
  "notes/red-berries.jpg": {
    author: "Pezibear",
    authorLink: "https://pixabay.com/users/Pezibear-526143/",
    link: "https://pixabay.com/photos/currant-red-red-currant-fruit-896223/",
    title: "Red Berries",
    ...License.Pixabay,
  },
  "notes/rose.jpg": {
    author: "schneeknirschen",
    authorLink: "https://pixabay.com/users/schneeknirschen-49643/",
    link: "https://pixabay.com/photos/rose-nature-flowers-spring-174817/",
    title: "Roses",
    ...License.Pixabay,
  },
  "notes/sandalwood.jpg": {
    author: "xbqs42",
    authorLink: "https://pixabay.com/users/xbqs42-2775906/",
    link: "https://pixabay.com/photos/santalum-album-indian-sandalwood-1607225/",
    title: "Sandalwood",
    ...License.Pixabay,
  },
  "notes/sorbet.jpg": {
    author: "ElizabethRose",
    authorLink: "https://pixabay.com/users/ElizabethRose-9211799/",
    link: "https://pixabay.com/photos/sorbet-ice-cream-fruit-summer-3506768/",
    title: "Cold Sorbet",
    ...License.Pixabay,
  },
  "notes/stephanotis.jpg": {
    author: "Kor!An",
    authorLink: "https://commons.wikimedia.org/wiki/User:Kor!An",
    link: "https://commons.wikimedia.org/wiki/File:Stephanotis_floribunda_variegata_21.JPG",
    title: "Stephanotis",
    ...License.CCBYSA,
  },
  "notes/sweet-pea.jpg": {
    author: "Alicja",
    authorLink: "https://pixabay.com/users/_Alicja_-5975425/",
    link: "https://pixabay.com/photos/sweet-pea-scented-ornamental-plants-3474709/",
    title: "Sweet Pea",
    ...License.Pixabay,
  },
  "notes/syringa.jpg": {
    author: "PollyDot",
    authorLink: "https://pixabay.com/users/PollyDot-160618/",
    link: "https://pixabay.com/photos/syringa-vulgaris-purple-lilac-lilac-335104/",
    title: "Purple Syringa",
    ...License.Pixabay,
  },
  "notes/tuberose.jpg": {
    author: "SKsiddhartthan",
    authorLink: "https://commons.wikimedia.org/wiki/File:Tuberose_flower.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Tuberose_flower.jpg",
    title: "Tuberose Flower",
    ...License.CCBYSA,
  },
  "notes/vanilla.jpg": {
    author: "ted_major",
    authorLink: "https://www.flickr.com/photos/ted_major/",
    link: "https://www.flickr.com/photos/ted_major/6600956129",
    title: "Vanilla Bean",
    ...License.CCBYSA,
  },
  "notes/violet.jpg": {
    author: "AnnaER",
    authorLink: "https://pixabay.com/users/AnnaER-35513/",
    link: "https://pixabay.com/photos/flowers-pansy-plant-purple-violet-200270/",
    title: "Violet Flowers",
    ...License.Pixabay,
  },
  "notes/water-hyacinth.jpg": {
    author: "pixel2013",
    authorLink: "https://pixabay.com/users/pixel2013-2364555/",
    link: "https://pixabay.com/photos/water-hyacinth-flowers-nature-plant-1547721/",
    title: "Water Hyacinth",
    ...License.Pixabay,
  },
  "notes/wood.jpg": {
    author: "Couleur",
    authorLink: "https://pixabay.com/users/Couleur-1195798/",
    link: "https://pixabay.com/photos/wood-tree-spruce-picea-conifer-3212803/",
    title: "Tree Wood",
    ...License.Pixabay,
  },
  "notes/wormwood.jpg": {
    author: "vilinapetrova",
    authorLink: "https://pixabay.com/users/vilinapetrova-4436954/",
    link: "https://pixabay.com/photos/grass-wormwood-3267376/",
    title: "Wormwood",
    ...License.Pixabay,
  },
  "notes/ylang-ylang.jpg": {
    author: "mayapujiati",
    authorLink: "https://pixabay.com/users/mayapujiati-709488/",
    link: "https://pixabay.com/photos/ylang-ylang-flower-smell-yellow-1118057/",
    title: "Ylang Ylang",
    ...License.Pixabay,
  },

  // Noses
  "noses.jpg": {
    author: "tristanmimet",
    authorLink: "https://pixabay.com/fr/users/tristanmimet-3336272/",
    link: "https://pixabay.com/fr/photos/parfum-parfumerie-nez-1794111/",
    title: "Parfum Parfumerie",
    ...License.Pixabay,
  },
  "noses/alberto-morillas.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/anne-flipo.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/woman-women-business-woman-female-1353825/",
    title: "Business Woman",
    ...License.Pixabay,
  },
  "noses/annick-menardo.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/woman-women-business-woman-female-1353825/",
    title: "Business Woman",
    ...License.Pixabay,
  },
  "noses/antoine-lie.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/antoine-maisondieu.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/beatrice-piquet.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/woman-women-business-woman-female-1353825/",
    title: "Business Woman",
    ...License.Pixabay,
  },
  "noses/bernard-chant.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/bruno-jovanovic.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/carlos-benaim.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/christine-nagel.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/woman-women-business-woman-female-1353825/",
    title: "Business Woman",
    ...License.Pixabay,
  },
  "noses/christophe-laudamiel.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/christopher-wicks.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/clement-gavarry.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/daniela-roche-andrier.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/woman-women-business-woman-female-1353825/",
    title: "Business Woman",
    ...License.Pixabay,
  },
  "noses/dominique-ropion.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/emilio-valeros.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/francis-kurkdjian.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/frida-giannini.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/woman-women-business-woman-female-1353825/",
    title: "Business Woman",
    ...License.Pixabay,
  },
  "noses/gerard-haury.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/honorine-blanc.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/woman-women-business-woman-female-1353825/",
    title: "Business Woman",
    ...License.Pixabay,
  },
  "noses/jacques-cavallier.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/jacques-huclier.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/jean-claude-delville.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/laurent-le-guernec.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/loc-dong.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/maurice-roucel.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/michel-almairac.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/nuria-cruelles-borrull.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/woman-women-business-woman-female-1353825/",
    title: "Business Woman",
    ...License.Pixabay,
  },
  "noses/olivier-cresp.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/olivier-polge.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/richard-herpin.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/rodrigo-flores-roux.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/man-business-cartoon-businessman-1352025/",
    title: "Business Man",
    ...License.Pixabay,
  },
  "noses/sophia-grojsman.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/woman-women-business-woman-female-1353825/",
    title: "Business Woman",
    ...License.Pixabay,
  },
  "noses/veronique-nyberg.jpg": {
    author: "ROverhate",
    authorLink: "https://pixabay.com/users/ROverhate-1759589/",
    link: "https://pixabay.com/vectors/woman-women-business-woman-female-1353825/",
    title: "Business Woman",
    ...License.Pixabay,
  },

  // Pyramid & Layers
  "pyramid.jpg": {
    link: "https://www.auraperfumery.com/pyramid/",
    title: "Fragrance Pyramid",
    ...Author.LVX,
    ...License.CC0,
  },
  "layers/bottom.png": {
    link: "https://www.auraperfumery.com/pyramid/base/",
    title: "Pyramid Bottom",
    ...Author.LVX,
    ...License.CC0,
  },
  "layers/middle.png": {
    link: "https://www.auraperfumery.com/pyramid/heart/",
    title: "Pyramid Middle",
    ...Author.LVX,
    ...License.CC0,
  },
  "layers/top.png": {
    link: "https://www.auraperfumery.com/pyramid/head/",
    title: "Pyramid Top",
    ...Author.LVX,
    ...License.CC0,
  },

  // Seasons
  "seasons.jpg": {
    author: "Hermann",
    authorLink: "https://pixabay.com/fr/users/Hermann-130146/",
    link: "https://pixabay.com/fr/photos/horloge-astronomique-horloge-temps-408306/",
    title: "Horloge Astronomique Temps",
    ...License.Pixabay,
  },
  "seasons/spring.jpg": {
    author: "Gellinger",
    authorLink: "https://pixabay.com/users/Gellinger-201217/",
    link: "https://pixabay.com/photos/spring-awakening-spring-1197602/",
    title: "Spring Awakening",
    ...License.Pixabay,
  },
  "seasons/summer.jpg": {
    author: "Aknafi",
    authorLink: "https://pixabay.com/users/Aknafi-4630656/",
    link: "https://pixabay.com/photos/beach-summer-blue-summer-beach-2123440/",
    title: "Summer Beach",
    ...License.Pixabay,
  },
  "seasons/fall.jpg": {
    author: "esudroff",
    authorLink: "https://pixabay.com/users/esudroff-627167/",
    link: "https://pixabay.com/photos/fall-tree-leaf-wood-season-autumn-3057301/",
    title: "Fall Leaves",
    ...License.Pixabay,
  },
  "seasons/winter.jpg": {
    author: "gamagapix",
    authorLink: "https://pixabay.com/users/gamagapix-14212960/",
    link: "https://pixabay.com/users/esudroff-627167/",
    title: "Winter Tree",
    ...License.Pixabay,
  },

  // Times of Day & Clocks
  "times-of-day.jpg": {
    author: "jplenio",
    authorLink: "https://pixabay.com/fr/users/jplenio-7645255/",
    link: "https://pixabay.com/fr/photos/herbe-pr%C3%A9-or-ros%C3%A9e-goutte-de-ros%C3%A9e-3375344/",
    title: "Herbe Pré Or",
    ...License.Pixabay,
  },
  "clocks/any.jpg": {
    author: "Pexels",
    authorLink: "https://pixabay.com/users/Pexels-2286921/",
    link: "https://pixabay.com/photos/bar-bartender-beer-counter-drink-1869656/",
    title: "Coffee Counter",
    ...License.Pixabay,
  },
  "clocks/day.jpg": {
    author: "Pexels",
    authorLink: "https://pixabay.com/users/Pexels-2286921/",
    link: "https://pixabay.com/photos/architecture-bar-building-caf%C3%A9-1840263/",
    title: "Outdoor Cafe",
    ...License.Pixabay,
  },
  "clocks/night.jpg": {
    author: "RonPorter",
    authorLink: "https://pixabay.com/users/RonPorter-291009/",
    link: "https://pixabay.com/photos/berlin-christmas-market-visitors-563143/",
    title: "Holiday Market",
    ...License.Pixabay,
  },

  // Years
  "years.jpg": {
    author: "Jens Mohr",
    authorLink: "https://commons.wikimedia.org/wiki/Creator:Jens_Mohr",
    link: "https://commons.wikimedia.org/wiki/File:Dokumentation_av_utst%C3%A4llningen_Passion_f%C3%B6r_parfym,_2007,_Hallwylska_museet_-_Hallwylska_museet_-_86456.tif",
    title: "Passion for Perfume",
    ...License.CC0,
  },
  "years/1971.jpg": {
    author: "flynn_chris",
    authorLink: "https://pixabay.com/users/flynn_chris-140893/",
    link: "https://pixabay.com/photos/epcot-walt-disney-world-vacation-252810/",
    title: "Walt Disney World",
    ...License.Pixabay,
  },
  "years/1988.jpg": {
    author: "Ken Hackman",
    authorLink: "https://commons.wikimedia.org/wiki/File:Seoul_Olympic_torch.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Seoul_Olympic_torch.jpg",
    title: "1988 Summer Olympics",
    ...License.CC0,
  },
  "years/1994.jpg": {
    author: "Wasted Time R",
    authorLink: "https://en.wikipedia.org/wiki/User:Wasted_Time_R",
    link: "https://en.wikipedia.org/wiki/File:WorldCup1994GiantsStadiumQF.jpg",
    title: "1994 World Cup",
    ...License.CCBYSA,
  },
  "years/1996.jpg": {
    author: "Semnoz",
    authorLink: "https://fr.wikipedia.org/wiki/Utilisateur:Semnoz",
    link: "https://en.wikipedia.org/wiki/File:JO_Atlanta_1996_-_Stade.jpg",
    title: "1996 Summer Olympics",
    ...License.CC0,
  },
  "years/1997.jpg": {
    author: "PaddyBriggs",
    authorLink: "https://en.wikipedia.org/wiki/User:PaddyBriggs",
    link: "https://commons.wikimedia.org/wiki/File:Diana%27s_funeral.jpg",
    title: "Diana Funeral",
    ...License.CC0,
  },
  "years/1999.jpg": {
    author: "Kris1123",
    authorLink: "https://commons.wikimedia.org/wiki/User:Kris1123",
    link: "https://commons.wikimedia.org/wiki/File:MandalayBay2010.JPG",
    title: "Mandalay Bay",
    ...License.CCBY,
  },
  "years/2003.jpg": {
    author: "Jim Yi",
    authorLink: "https://www.flickr.com/photos/jimyi/",
    link: "https://www.flickr.com/photos/jimyi/3918683608/",
    title: "Video Music Awards",
    ...License.CCBYSA,
  },
  "years/2004.jpg": {
    author: "Alterego",
    authorLink: "https://commons.wikimedia.org/wiki/User:Alterego",
    link: "https://en.wikipedia.org/wiki/File:Olympic_flame_at_opening_ceremony.jpg",
    title: "2004 Summer Olympics",
    ...License.CCBYSA,
  },
  "years/2005.jpg": {
    author: "MarsTheGod",
    authorLink: "https://de.wikipedia.org/wiki/User:MarsTheGod",
    link: "https://en.wikipedia.org/wiki/File:M4100164.JPG",
    title: "Live 8 2005",
    ...License.CC0,
  },
  "years/2006.jpg": {
    author: "Magnus_Manske",
    authorLink: "https://commons.wikimedia.org/wiki/User:File_Upload_Bot_(Magnus_Manske)",
    link: "https://en.wikipedia.org/wiki/File:Neckarstadion_2011_crop.jpg",
    title: "2006 World Cup",
    ...License.CCBYSA,
  },
  "years/2007.jpg": {
    author: "Egghead06",
    authorLink: "https://en.wikipedia.org/wiki/User:Egghead06",
    link: "https://commons.wikimedia.org/wiki/File:EltonJohnDianaConcert.JPG",
    title: "2007 Diana Concert",
    ...License.CCBYSA,
  },
  "years/2008.jpg": {
    author: "Peter23",
    authorLink: "https://commons.wikimedia.org/wiki/User:Peter23",
    link: "https://en.wikipedia.org/wiki/File:Beijing_national_stadium.jpg",
    title: "Beijing Stadium",
    ...License.CCBYSA,
  },
  "years/2010.jpg": {
    author: "Boaworm",
    authorLink: "https://commons.wikimedia.org/wiki/User:Boaworm",
    link: "https://commons.wikimedia.org/wiki/File:Eyjafjallajokull_volcano_plume_2010_04_17.jpg#mw-jump-to-license",
    title: "Iceland Eruption",
    ...License.CCBY,
  },
  "years/2011.jpg": {
    author: "aurélien",
    authorLink: "https://www.flickr.com/photos/aguichard/",
    link: "https://www.flickr.com/photos/aguichard/5656784831/",
    title: "Regent Street",
    ...License.CCBYSA,
  },
  "years/2013.jpg": {
    author: "Melissa_Rose_14",
    authorLink: "https://www.flickr.com/people/98388875@N03",
    link: "https://commons.wikimedia.org/wiki/File:Miley_Cyrus_Jingle_Ball_2013_6.jpg",
    title: "Jingle Ball 2013",
    ...License.CCBY,
  },
  "years/2014.jpg": {
    author: "Ganoshenko Roman",
    authorLink: "https://commons.wikimedia.org/wiki/File:%D0%9C%D0%BE%D1%80%D1%81%D0%BA%D0%BE%D0%B9_%D0%BF%D0%BE%D1%80%D1%82_%D0%A1%D0%BE%D1%87%D0%B8_%D0%B8_%D0%BF%D1%80%D0%B8%D0%BB%D0%B5%D0%B3%D0%B0%D1%8E%D1%89%D0%B0%D1%8F_%D1%82%D0%B5%D1%80%D1%80%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D1%8F_04.jpg",
    link: "https://commons.wikimedia.org/wiki/File:%D0%9C%D0%BE%D1%80%D1%81%D0%BA%D0%BE%D0%B9_%D0%BF%D0%BE%D1%80%D1%82_%D0%A1%D0%BE%D1%87%D0%B8_%D0%B8_%D0%BF%D1%80%D0%B8%D0%BB%D0%B5%D0%B3%D0%B0%D1%8E%D1%89%D0%B0%D1%8F_%D1%82%D0%B5%D1%80%D1%80%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D1%8F_04.jpg",
    title: "Port of Sochi",
    ...License.CCBYSA,
  },
  "years/2015.jpg": {
    author: "Huntley Paton",
    authorLink: "https://www.flickr.com/photos/huntleypaton/",
    link: "https://www.flickr.com/photos/huntleypaton/16464994135/in/photostream/",
    title: "2015 Super Bowl",
    ...License.CCBYSA,
  },
  "years/2017.jpg": {
    author: "Mobilus In Mobili",
    authorLink: "https://www.flickr.com/people/52257493@N00",
    link: "https://commons.wikimedia.org/wiki/File:Women%27s_March_on_Washington_(32593123745).jpg",
    title: "Women's March",
    ...License.CCBYSA,
  },
  "years/2018.jpg": {
    author: "Thephilipmicah",
    authorLink: "https://commons.wikimedia.org/w/index.php?title=User:Thephilipmicah&action=edit&redlink=1",
    link: "https://en.wikipedia.org/wiki/File:Beyonce_Performing_at_Coachella.jpg",
    title: "2018 Beyonce",
    ...License.CCBYSA,
  },
  "years/2019.jpg": {
    author: "Howcheng",
    authorLink: "https://commons.wikimedia.org/wiki/User:Howcheng",
    link: "https://commons.wikimedia.org/wiki/File:FIFA_Women%27s_World_Cup_2019_Final_-_US_team_on_podium_(4).jpg",
    title: "2019 World Cup",
    ...License.CCBYSA,
  },
}
