import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import { display as dateDisplay } from '../../lib/date'

import Link from './link'

import style from './footer.module.css'


export default () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      ...SiteInfo
    }
  `)

  const { buildTime, siteMetadata } = data.site
  const { links, title, version } = siteMetadata
  const buildYear = new Date(buildTime).getFullYear()
  const buildStamp = dateDisplay(buildTime)

  return (
    <footer className={style.footer}>
      <div className={style.links}>
        {/* @TODO list markup */}
        <Link href={links.home}>Home</Link>
        {' | '}
        <Link href={links.privacy}>Privacy</Link>
        {' | '}
        <Link href={links.contact.home}>Contact</Link>
        {' | '}
        <Link href={links.sitemap.home}>Sitemap</Link>
      </div>
      <div className={style.disclosure}>
        <div id="amazon-audience-disclosure-site">
          {/* @TODO move to header? seems suggested. */}
          As an Amazon Associate, we earn from qualifying
          purchases.
        </div>
        <div id="amazon-data-disclaimer">
          Certain content that appears on this site comes from Amazon.com.<br/>
          This content is provided "as is", and is subject to change or
          removal at any time.
        </div>
      </div>
      <div className={style.legal}>
        © {buildYear} {title}
      </div>
      <div className={style.version}>
        v{version} @ {buildStamp}
      </div>
    </footer>
  )
}
