/**
 * Safe/simple wrapper for Google Analytics gtag.js function.
 */
export const emitAnalyticsEvent = (
  category, action, label = null, value = null
) => {
  // console.log('GAevent!', {category, action, label, value})
  if (
    (typeof window == 'undefined') ||
    !('gtag' in window)
  ) return false

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  })
  return true
}
