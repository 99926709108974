import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import style from './list.module.css'


const List = ({ basement, children, className, ordered }) => {
  const ListTag = ordered ? 'ol' : 'ul'

  return (
    <ListTag className={
      classNames(style.list, className, { [`${style.basement}`]: basement })
    }>
      {children}
    </ListTag>
  )
}

List.defaultProps = {
  basement: true,
  ordered:  false,
}

List.propTypes = {
  basement: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  ordered:  PropTypes.bool.isRequired,
}

export default List
