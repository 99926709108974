/**
 * Filter a list of keywords:
 *  - All lowercase words
 *  - Deduplicate repeating words
 */
exports.filter = (keywords) => {
  const lowercase = keywords.map(word => word.toLowerCase())
  const unique = Array.from(new Set(lowercase))
  return unique
}
