const { filter: keywordFilter } = require('./keyword')


/**
 *
 */
class Schema {
  /**
   *
   */
  constructor({ site }) {
    this.site = site

    this.config = this.site.siteMetadata
    this.links = this.config.links
    this.meta = this.config.meta

    this.accessMode = [
      'chartOnVisual',
      'disagramOnVisual',
      'textOnVisual',
      'textual',
      'visual'
    ]
    this.accessibilityAPI = 'ARIA'
    this.accessibilityControl = [
      'fullKeyboardControl',
      'fullMouseControl',
      'fullTouchControl',
    ]
    this.accessibilityFeature = [
      'alternativeText',
      'displayTransformability',
      'highContrastDisplay',
      'longDescription',
      'index',
      'structuralNavigation',
      'unlocked',
    ]
    this.accessibilityHazard = [
      'noFlashingHazard',
      'noMotionSimulationHazard',
      'noSoundHazard'
    ]
    this.accessModeSufficient = [
      this.ItemList({
        description: 'Text and images',
        identifier: 'access-mode-sufficient-textual-visual',
        itemListElement: [ 'textual', 'visual' ],
        name: 'Text and images',
      }),
      this.ItemList({
        description: 'Text with textual alternatives for images',
        identifier: 'access-mode-sufficient-textual',
        itemListElement: [ 'textual' ],
        name: 'Text with textual alternatives for images',
      })
    ]
    this.contentRating = 'MPAA G'  // @TODO adjust for ad campaigns
    this.copyrightYear = new Date(this.meta.founded).getUTCFullYear()
    this.creativeWorkStatus = 'Published'
    this.dateCreated = this.meta.founded
    this.dateModified = new Date(this.site.buildTime).toISOString()
    this.datePublished = this.meta.founded
    this.genre = this.config.description
    this.isAccessibleForFree = true
    this.isFamilyFriendly = true  // @TODO adjust for ad campaigns
    this.inLanguage = 'English'
    this.version = this.config.version
  } // constructor


  // GETTERS

  /**
   *
   */
  breadcrumbs(trail) {
    const first = {
      name: 'Home',
      slug: '/',
    }
    const crumbs = [ first ].concat(trail)
    const last = crumbs[crumbs.length - 1]
    const itemListElement = crumbs.map((crumb, index) => this.ListItem({
      description: 'Breadcrumb Trail List Item',
      identifier: this.uri(crumb.slug),
      item: this.Thing({
        description: 'Breadcrumb Trail List Item',
        identifier: this.uri(crumb.slug),
        name: crumb.name,
        url: this.uri(crumb.slug),
      }),
      name: crumb.name,
      position: (index + 1),
      url: this.uri(crumb.slug),
    }))

    return this.BreadcrumbList({
      description: 'WebSite Navigation Breadcrumb Trail List',
      itemListElement,
      name: 'Breadcrumb Trail List',
      url: this.uri(last.slug),
    })
  } // breadcrumbs

  /**
   *
   */
  company() {
    return this.Corporation({
      address: this.companyAddress(),
      description: this.config.description,
      foundingDate: this.meta.founded,
      location: this.companyAddress(),
      knowsAbout: this.config.description,
      knowsLanguage: this.inLanguage,
      name: this.config.title,
      slogan: this.config.description,
      url: this.uri(),
    })
  } // company

  /**
   *
   */
  companyAddress() {
    return this.PostalAddress({
      addressCountry: this.meta.hq.country,
      addressLocality: this.meta.hq.city,
      addressRegion: this.meta.hq.state,
    })
  } // companyAddress

  /**
   *
   */
  type(name) {
    return {
      '@context': 'http://schema.org',
      '@type': name,
    }
  } // type

  /**
   *
   */
  uri(slug = '') {
    return this.config.siteUrl + slug
  }


  // SCHEMA.ORG

  /**
   *
   */
  AggregateRating({
    ratingCount,  // ratings,
    ratingValue,  // rating
    reviewCount,  // reviews
  }) {
    return {
      ...this.type('AggregateRating'),
      ratingCount,
      ratingValue,
      reviewCount,
    }
  } // AggregateRating

  /**
   *
   */
  Brand({
    description,
    identifier,
    image,
    logo,
    name,
    slogan,
    url,
  }) {
    return {
      ...this.type('Brand'),
      description,
      identifier,
      image,
      logo,
      name,
      slogan,
      url,
    }
  } // Brand

  /**
   *
   */
  BreadcrumbList({
    description,
    itemListElement,
    name,
    numberOfItems = itemListElement.length,
    url,
  }) {
    return {
      ...this.type('BreadcrumbList'),
      description,
      itemListElement,
      name,
      numberOfItems,
      url,
    }
  } // BreadcrumbList

  /**
   *
   */
  Corporation({
    address,
    description,
    foundingDate,
    image,
    knowsAbout,
    knowsLanguage = this.inLanguage,
    location,
    name,
    slogan,
    url,
  }) {
    return {
      ...this.type('Corporation'),
      address,
      description,
      foundingDate,
      knowsAbout,
      knowsLanguage,
      image,
      location,
      name,
      slogan,
      url,
      // email,
      // logo,
      // parentOrganization,
      // telephone,
    } // return
  } // Corporation

  /**
   *
   */
  GeoCoordinates({
    latitude,
    longitude,
  }) {
    return {
      ...this.type('GeoCoordinates'),
      latitude,
      longitude,
    }
  } // GeoCoordinates

  /**
   *
   */
  ItemList({
    description,
    identifier,
    image,
    itemListElement,
    itemListOrder = this.ItemListOrderAscending(),
    name,
    numberOfItems = itemListElement.length,
    url,
  }) {
    return {
      ...this.type('ItemList'),
      description,
      identifier,
      image,
      itemListElement,
      itemListOrder,
      name,
      numberOfItems,
      url,
    } // return
  } // ItemList

  /**
   *
   */
  ItemListOrderAscending() {
    return this.type('ItemListOrderAscending')
  } // ItemListOrderAscending

  /**
   *
   */
  ListItem({
    description,
    identifier,
    item,
    image,
    name,
    position,
    url,
  }) {
    return {
      ...this.type('ListItem'),
      description,
      identifier,
      image,
      item,
      name,
      position,
      url,
    }
  } // ListItem

  /**
   *
   */
  NewCondition() {
    return this.type('NewCondition')
  } // NewCondition

  /**
   *
   */
  PeopleAudience({
    audienceType = this.config.description,
    requiredGender = this.meta.gender,
    suggestedGender = this.meta.gender,
  } = {}) {
    return {
      ...this.type('PeopleAudience'),
      audienceType,
      requiredGender,
      suggestedGender,
    }
  } // PeopleAudience

  /**
   *
   */
  Person({
    description,
    gender,
    identifier,
    image,
    jobTitle,
    knowsAbout,
    name,
    url,
  }) {
    return {
      ...this.type('Person'),
      description,
      gender,
      identifier,
      image,
      jobTitle,
      knowsAbout,
      name,
      url,
    }
  } // Person

  /**
   *
   */
  Place({
    description,
    geo,
    hasMap,
    identifier,
    isAccessibleForFree = this.isAccessibleForFree,
    image,
    latitude,
    longitude,
    name,
    photo,
    slogan,
    url,
  }) {
    return {
      ...this.type('Place'),
      description,
      geo,
      hasMap,
      identifier,
      isAccessibleForFree,
      image,
      latitude,
      longitude,
      name,
      photo,
      slogan,
      url,
    }
  } // Place

  /**
   *
   */
  PostalAddress({
    addressCountry,
    addressLocality,
    addressRegion,
    availableLanguage = this.inLanguage,
  }) {
    return {
      ...this.type('PostalAddress'),
      addressCountry,
      addressLocality,
      addressRegion,
      availableLanguage,
      // email,
      // faxNumber,
      // postalCode,
      // streetAddress,
      // telephone,
    } // return
  } // PostalAddress

  /**
   *
   */
  Product({
    aggregateRating,
    audience = this.PeopleAudience(),
    brand,
    category,
    color = null,
    description,
    identifier,
    image,
    isSimilarTo = null,
    itemCondition = this.NewCondition(),
    logo,
    material,
    model,
    name,
    productID,
    releaseDate,
    sku,
    slogan,
    url,
    weight,
  }) {
    return {
      ...this.type('Product'),
      aggregateRating,
      audience,
      brand,
      category,
      color,
      description,
      identifier,
      image,
      isSimilarTo,
      itemCondition,
      logo,
      material,
      model,
      name,
      productID,
      releaseDate,
      sku,
      slogan,
      url,
      weight,
    }
  } // Product

  /**
   *
   */
  QuantitativeValue({
    unitText,
    value,
  }) {
    return {
      ...this.type('QuantitativeValue'),
      unitText,
      value,
    }
  } // QuantitativeValue

  /**
   *
   */
  Thing({
    description,
    identifier,
    image,
    name,
    url,
  }) {
    return {
      ...this.type('Thing'),
      '@id': identifier,
      description,
      identifier,
      image,
      name,
      url,
    }
  } // Thing

  /**
   *
   */
  WebPage({ schema }) {
    const {
      breadcrumb, description, image, name, slug,
      keywords = [],
      brand, brands,
      person, persons,
      place, places,
      product, products,
      thing, things,
    } = schema
    let pageType = 'Web'
    let pageEntity, pageImage

    // options prep
    const itemListParams = {
      description,
      identifier: this.uri(slug),
      name,
      url: this.uri(slug),
    }
    const getBrandOpts = brand => ({
      description: brand.about,
      identifier: this.uri(brand.slug),
      // image: brand.logo && this.uri(image),
      // logo: brand.logo && this.uri(image),
      name: brand.name,
      slogan: brand.slogan,
      url: this.uri(brand.slug),
    })
    const getPersonOpts = person => ({
      description: person.about,
      gender: person.gender,
      identifier: this.uri(person.slug),
      // image: person.image && this.uri(image),
      jobTitle: 'Perfumer',
      knowsAbout: this.config.description,
      name: person.name,
      url: this.uri(person.slug),
    })
    const getPlaceOpts = place => ({
      description: place.about,
      // geo: this.GeoCoordinates({
      //   latitude: place.geo.lat,
      //   longitude: place.geo.lon,
      // }),
      // hasMap: place.map && this.uri(place.map),
      identifier: this.uri(place.slug),
      // image: place.image && this.uri(image),
      // latitude: place.geo.lat,
      // longitude: place.geo.lon,
      name: place.name,
      // photo: place.image && this.uri(image),
      slogan: place.about,
      url: this.uri(place.slug),
    })
    const getProductOpts = product => ({
      aggregateRating: this.AggregateRating({
        ratingCount: product.ratings,
        ratingValue: product.rating,
        reviewCount: product.reviews,
      }),
      audience: this.PeopleAudience(),
      brand: this.Brand({
        description: product.brand.about,
        identifier: this.uri(product.brand.slug),
        // image: this.uri(product.brand.logo),
        // logo: this.uri(product.brand.logo),
        name: product.brand.name,
        slogan: product.brand.slogan,
        url: this.uri(product.brand.slug),
      }),
      category: this.config.description,
      color: null,
      description: product.about,
      identifier: this.uri(product.slug),
      image: product.image,
      isSimilarTo: null,
      itemCondition: this.NewCondition(),
      // logo: this.uri(product.brand.logo),
      material: this.meta.material,
      model: product.caption,
      name: product.caption,
      productID: product.id,
      releaseDate: new Date(product.year).getUTCDate(),
      sku: product.id,
      slogan: product.about,
      url: this.uri(product.slug),
      weight: this.QuantitativeValue({
        unitText: 'fluid ounces',
        value: product.size,
      }),
    })
    const getThingOpts = thing => ({
      description: thing.about,
      identifier: this.uri(thing.slug),
      // image: thing.image && this.uri(image),
      name: thing.name,
      url: this.uri(thing.slug),
    })

    // image prep
    if (image) {
      pageImage = image.match(/^http/) ? image : this.uri(image)
    }

    // about/mainEntity prep
    if (brands) {
      pageEntity = this.ItemList({
        itemListElement: brands.map(brand => this.Brand(getBrandOpts(brand))),
        ...itemListParams,
      })
    }
    else if (brand) {
      pageEntity = this.Brand(getBrandOpts(brand))
    }
    else if (persons) {
      pageEntity = this.ItemList({
        itemListElement: persons.map(
          person => this.Person(getPersonOpts(person))
        ),
        ...itemListParams,
      })
    }
    else if (person) {
      pageEntity = this.Person(getPersonOpts(person))
    }
    else if (place && places) {
      const placeList = [ this.Place(getPlaceOpts(place)) ].concat(
        places.map(placeItem => this.Place(getPlaceOpts(placeItem)))
      )
      pageEntity = this.ItemList({
        itemListElement: placeList,
        ...itemListParams,
      })
    }
    else if (places) {
      pageEntity = this.ItemList({
        itemListElement: places.map(place => this.Place(getPlaceOpts(place))),
        ...itemListParams,
      })
    }
    else if (place) {
      pageEntity = this.Place(getPlaceOpts(place))
    }
    else if (products) {
      pageEntity = this.ItemList({
        itemListElement: products.map(
          product => this.Product(getProductOpts(product))
        ),
        ...itemListParams,
      })
    }
    else if (product) {
      pageEntity = this.Product(getProductOpts(product))
    }
    else if (thing && things) {
      const thingList = [ this.Thing(getThingOpts(thing)) ].concat(
        things.map(thingItem => this.Thing(getThingOpts(thingItem)))
      )
      pageEntity = this.ItemList({
        itemListElement: thingList,
        ...itemListParams,
      })
    }
    else if (things) {
      pageEntity = this.ItemList({
        itemListElement: things.map(thing => this.Thing(getThingOpts(thing))),
        ...itemListParams,
      })
    }
    else if (thing) {
      pageEntity = this.Thing(getThingOpts(thing))
    }

    // type of web page prep
    if (pageEntity) {
      // more: About, Contact, FAQ, Profile, QA, SearchResults
      if (pageEntity['@type'] === 'ItemList') {
        pageType = 'Collection'
      }
      else {
        pageType = 'Item'
      }
    }

    const {
      about = pageEntity,
      abstract = description,
      accessMode,
      accessibilityAPI,
      accessibilityControl,
      accessibilityFeature,
      accessibilityHazard,
      accessModeSufficient,
      audience = this.PeopleAudience(),
      author = this.company(),
      contentRating,
      copyrightHolder = this.company(),
      copyrightYear,
      creativeWorkStatus,
      creator = this.company(),
      dateCreated,
      dateModified,
      datePublished,
      genre,
      identifier = this.uri(slug),
      inLanguage,
      isAccessibleForFree,
      isFamilyFriendly,
      lastReviewed = this.dateModified,
      license = null,
      mainEntity = pageEntity,
      mentions = null,
      primaryImageOfPage = pageImage,
      text = description,
      thumbnailUrl = null,
      url = this.uri(slug),
      version,
    } = this

    return {
      ...this.type(pageType + 'Page'),
      about,
      abstract,
      accessMode,
      accessModeSufficient,
      accessibilityAPI,
      accessibilityControl,
      accessibilityFeature,
      accessibilityHazard,
      audience,
      author,
      breadcrumb: breadcrumb && this.breadcrumbs(breadcrumb),
      contentRating,
      copyrightHolder,
      copyrightYear,
      creativeWorkStatus,
      creator,
      dateCreated,
      dateModified,
      datePublished,
      description,
      genre,
      identifier,
      image: pageImage,
      inLanguage,
      isAccessibleForFree,
      isFamilyFriendly,
      keywords: keywordFilter(keywords.concat(this.config.keywords)),
      lastReviewed,
      license,
      mainEntity,
      mentions,
      name,
      primaryImageOfPage,
      text,
      thumbnailUrl,
      url,
      version,
    } // return
  } // WebPage

  /**
   *
   */
  WebSite({ hasPart } = {}) {
    const {
      abstract = this.config.description,
      accessMode,
      accessibilityAPI,
      accessibilityControl,
      accessibilityFeature,
      accessibilityHazard,
      accessModeSufficient,
      audience = this.PeopleAudience(),
      author = this.company(),
      contentRating,
      copyrightHolder = this.company(),
      copyrightYear,
      creativeWorkStatus,
      creator = this.company(),
      dateCreated,
      dateModified,
      datePublished,
      description = this.config.description,
      genre,
      identifier = this.uri(),
      image,
      isAccessibleForFree,
      isFamilyFriendly,
      inLanguage,
      keywords = keywordFilter(this.config.keywords),
      license = null,
      name = this.config.title,
      potentialAction = null,
      thumbnailUrl = null,
      url = this.uri(),
      version,
    } = this

    return {
      ...this.type('WebSite'),
      abstract,
      accessMode,
      accessModeSufficient,
      accessibilityAPI,
      accessibilityControl,
      accessibilityFeature,
      accessibilityHazard,
      audience,
      author,
      contentRating,
      copyrightHolder,
      copyrightYear,
      creativeWorkStatus,
      creator,
      dateCreated,
      dateModified,
      datePublished,
      description,
      genre,
      hasPart,
      identifier,
      image,
      inLanguage,
      isAccessibleForFree,
      isFamilyFriendly,
      keywords,
      license,
      name,
      potentialAction,
      thumbnailUrl,
      url,
      version,
    } // return
  } // WebSite
} // Schema


// EXPORT
module.exports = {
  Schema,
}
