import { graphql, useStaticQuery } from 'gatsby'
import HighlightText from '@brianmcallister/react-highlight-text'
import React, { useRef, useState } from 'react'
import useClickOutside from 'use-click-outside'
import { useDebouncedCallback } from 'use-debounce'
import { useFlexSearch } from 'react-use-flexsearch'
import { useQueryParam, StringParam } from 'use-query-params'

import { emitAnalyticsEvent } from '../scripts/analytics'

import Image from './image'
import Link from './link'
import List from './list'
import ListItem from './list-item'

import style from './search.module.css'

import iconClose from '../images/icons/close.svg'
import iconSearch from '../images/icons/search.svg'


const RESULTS = 20


/**
 * Search
 */
const Search = () => {
  // hooks
  const ref = useRef()
  const [debounceQuery] = useDebouncedCallback(callback => callback(), 750)
  const [paramQ, setParamQ] = useQueryParam('q', StringParam)
  const [query, setQuery] = useState(paramQ || '')

  // data
  const data = useStaticQuery(graphql`
    query SearchQuery {
      ...SiteInfo
      ...Search
    }
  `)
  const {links} = data.site.siteMetadata
  const {index, store} = data.localSearchPages
  const results = useFlexSearch(query, index, JSON.parse(store), {
    // ?boost: #,
    limit: RESULTS,
    // page: cursor|true,
    // suggest: true,
  })

  // events
  const handleFormSubmit = event => event.preventDefault()
  const handleInputChange = event => {
    const { value: term } = event.currentTarget
    setQuery(term)
    debounceQuery(() => {
      setParamQ(term.length && term)
      term.length && emitAnalyticsEvent('search', 'query', term)
    })
  }
  const handleSearchClose = event => {
    event && event.preventDefault()
    setQuery('')
    setParamQ()
    emitAnalyticsEvent('search', 'close')
  }
  const handleResultClick = event => {
    emitAnalyticsEvent('search', 'result', event.currentTarget.pathname)
  }
  useClickOutside(ref, () => {
    (results.length > 0) && handleSearchClose()
  })

  return (
    <div className={style.search} ref={ref}>
      <form action={links.fragrances} onSubmit={handleFormSubmit}>
        <label htmlFor="q">
          <Image
            alt="Search Icon"
            className={style.iconSearch}
            inline={true}
            src={iconSearch}
          />
          <span className={style.label}>Search:</span>
          <input
            aria-label="Search"
            className={style.field}
            id="q"
            name="q"
            onChange={handleInputChange}
            placeholder="Search"
            type="search"
            value={query}
          />
          <Image
            alt="Clear Icon"
            className={style.iconClear}
            inline={true}
            onClick={handleSearchClose}
            src={iconClose}
          />
        </label>
      </form>

      {(results.length > 0) && (
        <div className={style.results}>
          <div className={style.meta}>
            <span>
              {results.length}
              {results.length >= RESULTS && '+'}
              {' '} results for “<strong>{query}</strong>”:
            </span>
            <Image
              alt="Close Icon"
              className={style.iconClose}
              inline={true}
              onClick={handleSearchClose}
              src={iconClose}
            />
          </div>
          <List basement={true} className={style.list}>
            {results.map(result => (
              <ListItem key={result.id}>
                <Link href={result.path} onClick={handleResultClick}>
                  <HighlightText text={result.title} words={[query]}/>
                </Link>
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </div>
  )
}

export default Search
