import classNames from 'classnames'
import GatsbyImage from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'

import ImageCredit from './image-credit'

import style from './image.module.css'


/**
 *
 */
const Image = ({
  alt, amazonSize, className, credit, fixed, inline, onClick, src
}) => {
  const imageClass = classNames(style.image, className)
  const imageParams = { alt, className: imageClass, onClick }
  let ImageTag

  if (fixed) {
    ImageTag = GatsbyImage
    imageParams.fixed = fixed
  }
  else if (src) {
    if (amazonSize) {
      // _SL_ = crop by integer
      // _CL_? = autocrop?
      src = src.replace(/\.jpg$/, `._SL${amazonSize}_.jpg`)
    }
    ImageTag = 'img'
    imageParams.src = src
  }

  return (
    <div className={classNames(style.imageWrap, {[`${style.inline}`]: inline})}>
      <ImageTag {...imageParams} />
      {credit && (
        <ImageCredit path={credit} />
      )}
    </div>
  )
}

Image.defaultProps = {
  inline: false,
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  amazonSize: PropTypes.number,
  className: PropTypes.string,
  credit: PropTypes.string,
  fixed: PropTypes.object,
  inline: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  src: PropTypes.string,
}

export default Image
