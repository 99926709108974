import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import React from 'react'

import { filter as keywordFilter } from '../../lib/keyword'
import { Schema } from '../../lib/schema'


const SEO = ({ description, keywords, lang, meta, schema, title }) => {
  const { site } = useStaticQuery(graphql`
    query SeoQuery {
      ...SiteInfo
    }
  `)

  const defaultTitle = [
    site.siteMetadata.title,
    site.siteMetadata.description,
  ].join(' - ')
  const metaDescription = description || title || site.siteMetadata.description
  const metaKeywords = keywordFilter(
    keywords.concat(site.siteMetadata.keywords)
  )
  const schemaOrg = new Schema({ site })
  const schemaOrgJsonLd = JSON.stringify(
    schemaOrg.WebSite({
      hasPart: schema && schemaOrg.WebPage({ schema }),
    })
  )

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={defaultTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      title={title}
      meta={[
        {
          name: 'author',
          content: site.siteMetadata.author,
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:site_name',
          content: site.siteMetadata.title,
        },
        {
          property: 'og:title',
          content: title || defaultTitle,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:title',
          content: title || defaultTitle,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {schemaOrgJsonLd}
      </script>
    </Helmet>
  )
}
SEO.defaultProps = {
  description: '',
  keywords: [],
  lang: 'en',
  meta: [],
}
SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  schema: PropTypes.object,
  title: PropTypes.string,
}

export default SEO
