import classNames from 'classnames'
import { Link as LocalLink } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import PropTypes from 'prop-types'
import React from 'react'
import url from 'url'

import style from './link.module.css'


/**
 *
 * @param {*} param0
 */
const Link = ({
  affiliateId, button, children, className, href, onClick, rel
}) => {
  const outbound = /^https?:\/\//.test(href)
  const LinkTag = outbound ? OutboundLink : LocalLink
  const destAttr = outbound ? 'href' : 'to'
  const attrs = { onClick, rel }

  attrs[destAttr] = href
  attrs.className = classNames(
    button ? style.button : style.link,
    className
  )

  if (outbound && affiliateId && affiliateId.length > 0) {
    // @TODO skip if no disable-affiliate cookie set by user via privacy
    const affiliateUrl = url.parse(attrs[destAttr], true)
    affiliateUrl.query.tag = affiliateId
    attrs[destAttr] = url.format(affiliateUrl)
  }

  return (
    <LinkTag {...attrs}>
      {children}
    </LinkTag>
  )
}

Link.defaultProps = {
  button: false,
}

Link.propTypes = {
  affiliateId: PropTypes.string,
  button: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  rel: PropTypes.string,
}

export default Link
