import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import Link from './link'
import Search from '../components/search'

import style from './header.module.css'


export default () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      ...SiteInfo
    }
  `)

  const {links, title} = data.site.siteMetadata

  return (
    <header className={style.header}>
      <div className={style.title}>
        <Link href={links.home}>
          {title}
        </Link>
      </div>
      <div className={style.search}>
        <Search/>
      </div>
    </header>
  )
}
