import PropTypes from 'prop-types'
import React from 'react'

import Link from './link'

import style from './image-credit.module.css'

import attributions from '../images/Credits'


/**
 * ImageCredit
 * @param {*} param0
 */
const ImageCredit = ({ path }) => {
  const credit = attributions[path]

  if (! credit) return null

  return (
    <div className={style.imageCredit}>
      Credit: {' '}
      <Link href={credit.link} rel="nofollow">{credit.title}</Link>
      {' '} by  {' '}
      <Link href={credit.authorLink} rel="nofollow">{credit.author}</Link>.
      {' '}
      <Link href={credit.licenseLink} rel="nofollow">{credit.license}</Link>
      {' '} License. {' '} Modified.
    </div>
  )
}
ImageCredit.propTypes = {
  path: PropTypes.string.isRequired,
}

export default ImageCredit
