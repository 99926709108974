import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import style from './heading.module.css'


const Heading = ({ basement, children, id, level }) => {
  const HeadingTag = `h${level}`

  return (
    <HeadingTag
      id={id}
      className={classNames(
        style.heading,
        style[HeadingTag],
        { [`${style.basement}`]: basement }
      )}
    >
      {children}
    </HeadingTag>
  )
}

Heading.propTypes = {
  basement: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  level: PropTypes.oneOf([ 1, 2, 3, 4, 5, 6 ]),
}

Heading.defaultProps = {
  basement: true,
  level: 1,
}

export default Heading
