import PropTypes from 'prop-types'
import React from 'react'

import style from './list-item.module.css'


const ListItem = ({ children }) => (
  <li className={style.listItem}>
    {children}
  </li>
)
ListItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ListItem
