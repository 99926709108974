import React from 'react'

import Footer from './footer'
import Header from './header'

import style from './layout.module.css'

import './fragments'


export default ({ children }) => (
  <>
    <Header />
    <div className={style.layout}>
      <main className={style.main}>
        {children}
      </main>
    </div>
    <Footer />
  </>
)
